import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://backoffice-ecgz.onrender.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'ModernHealth',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/219cbd2b-4125-4784-dbc2-f0c4872da200/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#4B7DDD',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fffff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#fffff',
};
